import './modalImg.css'

const Modal = ({ clickedImg, handleRotationRight, handleRotationLeft, setClickedImg }) => {
    const handleClick = e => {
        if(e.target.classList.contains("dismiss")) {
            setClickedImg(null)
        }
    }
    return <>
    <div className="overlay dismiss" onClick={handleClick}>
        <img src={clickedImg} alt="" />
        <span className="dismiss" style={{color: "bisque"}}  onClick={handleClick}>X</span>
        <div onClick={handleRotationLeft} className="overlay-arrows-left">
            <div className="div-arrow">
                <svg width="30" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" fill="bisque" clip-rule="evenodd"><path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"/></svg>
            </div>
        </div>            
        <div onClick={handleRotationRight} className="overlay-arrows-right">
            <div className="div-arrow">
                <svg width="20" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" fill="bisque" clip-rule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>
            </div>
        </div>
    </div>
    </>
}

export default Modal