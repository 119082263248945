import React, { Fragment } from "react";
import './about.css'
import meli3 from '../../static/meli/meli3.jpg'
import { useTranslation } from "react-i18next";

export default function About() {
    const [t] = useTranslation("global")
    return (
        <Fragment>
            <div id="about" className="fragment-about">
                    <div className="row-about">
                        <div className="col-p-about" data-aos="fade-right">
                            <div className="title-about">
                                <h2 className="title-h2-about">{t("about.title")}</h2>
                            </div>
                            <div className="subtitle-about">
                                <h3>{t("about.subtitle")}</h3>
                                <hr />
                                <p>{t("about.paragraph")}</p>
                            </div>                  
                        </div>
                        <div className="col-img-about" data-aos="zoom-in">
                            <img className="card-about" src={meli3} alt="" />
                        </div>
                    </div>
            </div>
        </Fragment>
    )
}