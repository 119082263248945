import React, { Fragment } from "react";

export default function Location() {
    return (
        <Fragment>
            <div className="div-location">
                <div>

                </div>
                <iframe 
                    title="Mapa Simples Cosas"
                    className="googleMaps"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.171179931029!2d-71.2624901!3d-40.3802029!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5b14e14342a790d!2sSimples%20Cosas!5e0!3m2!1ses!2sar!4v1662141490043!5m2!1ses!2sar"
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </Fragment>
    )
}