import React, { Fragment } from "react";
import './contact.css'
import Form from '../form/Form'
import Logo from "../logo/Logo";
import Location from "../location/Location";
import whatsapp from '../../static/whatsapp.png'
import { useTranslation } from "react-i18next";

export default function Contact() {
    // const buttonUp = document.getElementById("btn-wsp")
    const [t, i18n] = useTranslation("global")
    const buttonUp = () => {
        const scroll = document.documentElement.scrollTop
        if (scroll > 500) return buttonUp.style.transform = "scale(1)"
        if (scroll < 500) return buttonUp.style.transform = "scale(1)"
    }
    return(
        <Fragment>
            <div data-testid='contact-1'  id="contact" className="row-form">
                <div className="text-form" data-aos="fade-up">
                <div>
                    <a className="contact-a-logo" href="/">
                    <h1 className="contact-title-form">Simples<b className="contact-b-title-form">Cosas</b></h1>
                    <hr className="contact-hr-title-form" />
                    <p className="contact-p-logo">{t("logo.name")}</p>
                    </a>
                </div>
                    <p>{t("contact.email")}</p>
                    <p>{t("contact.phone")}: +5492944700897</p>
                    <Location/>
                    <p>{t("contact.location")}</p>
                </div>
                <div id="contact" className="div-contact-form-general" data-aos="fade-up">
                    <Form/>
                </div>
                <div>
                    <a
                        href="https://api.whatsapp.com/send?phone=5492944700897"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={buttonUp}
                    >
                        <img src={whatsapp} alt="whatsapp" className="btn-wsp" />
                    </a>
                </div>
            </div>
        </Fragment>
    )
}