import React from 'react'
import WebpageContext from './WebpageContext'

export default function WebpageProvider({
    children,
    userBookingValue,
    handleChangeBookingValues
}) {
  return (
    <WebpageContext.Provider 
        value={{
            userBookingValue,
            handleChangeBookingValues
        }}
        >
        {children}
    </WebpageContext.Provider>
  )
}
