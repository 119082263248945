import React, { Fragment } from "react";
import './logo.css'
import { useTranslation } from "react-i18next";

export default function Logo() {
    const [t] = useTranslation("global")
    return(
        <Fragment>
            <a className="a-logo" href="/">
            <h1 className="title-form">Simples<b className="b-title-form">Cosas</b></h1>
            <hr className="hr-title-form" />
            <p className="p-logo">{t("logo.name")}</p>
            </a>
        </Fragment>
    )
}