import React from "react";
import './reviews.css'
import reviewsData from '../../mockupData/reviewsData.json'
import { useTranslation } from "react-i18next";

export default function Reviews() {
    const [t, i18n] = useTranslation("global")
    return (
        <div id="reviews" className="div-reviews">
            {reviewsData.reviews.map(review => (
                <div key={review.id} className="card">
                    <img className="card-img" src={review.img} alt="" />
                    <p className="text-body">{t(`reviews.${review.id}.description`)}</p>
                    <p className="text-title">{t(`reviews.${review.id}.name`)}</p>
                </div>
            ))}                    
        </div>
    )
}