import React, { Fragment } from "react";
import './amenites.css'
import { useTranslation } from "react-i18next";

export default function Amenites() {
    const [t] = useTranslation("global")
    return(
        <Fragment>
            <div id="services" className="div-amenites">
                <div className="title-amenites">
                    <strong className="strong-title-amenites">{t("amenities.title")}</strong>
                </div>
                <div className="div-container-amenites">
                    <div className="col-amenites">
                        <div>
                            <h3>{t("amenities.parking.title")}</h3>
                            <li><span>&#10003;</span>{t("amenities.parking.description")}</li>
                        </div>
                        <div>
                            <h3>{t("amenities.services.title")}</h3>
                            <li><span>&#10003;</span>{t("amenities.services.description1")}</li>
                            <li><span>&#10003;</span>{t("amenities.services.description2")}</li>
                            <li><span>&#10003;</span>{t("amenities.services.description3")}</li>
                            <li><span>&#10003;</span>{t("amenities.services.description4")}</li>
                        </div>
                        <div>
                            <h3>{t("amenities.kitchen.title")}</h3>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.kitchen.description1")}</li>
                                <li><span>&#10003;</span>{t("amenities.kitchen.description2")}</li>
                                <li><span>&#10003;</span>{t("amenities.kitchen.description3")}</li>
                                <li><span>&#10003;</span>{t("amenities.kitchen.description4")}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-amenites">
                        <div>
                            <h3>{t("amenities.bedroom1.title")}</h3>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.bedroom1.description1")}</li>
                                <li><span>&#10003;</span>{t("amenities.bedroom1.description2")}</li>
                                <li><span>&#10003;</span>{t("amenities.bedroom1.description3")}</li>
                                <li><span>&#10003;</span>{t("amenities.bedroom1.description4")}</li>
                            </ul>
                        </div>
                    <div>
                            <h3>{t("amenities.bedroom2.title")}</h3>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.bedroom2.description1")}</li>
                                <li><span>&#10003;</span>{t("amenities.bedroom2.description2")}</li>
                                <li><span>&#10003;</span>{t("amenities.bedroom2.description3")}</li>
                                <li><span>&#10003;</span>{t("amenities.bedroom2.description4")}</li>
                                <li><span>&#10003;</span>{t("amenities.bedroom2.description5")}</li>
                            </ul>
                        </div>
                        <div>
                            <h3>{t("amenities.livingRoom.title")}</h3>
                            <p>{t("amenities.livingRoom.subtitle")}</p>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.livingRoom.description1")}</li>
                                <li><span>&#10003;</span>{t("amenities.livingRoom.description2")}</li>
                                <li><span>&#10003;</span>{t("amenities.livingRoom.description3")}</li>
                                <li><span>&#10003;</span>{t("amenities.livingRoom.description4")}</li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className="col-amenites">
                        <div>
                            <h3>{t("amenities.cabinFacilities.title")}</h3>
                            <p>{t("amenities.cabinFacilities.subtitle")}</p>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.cabinFacilities.description1")}</li>
                                <li><span>&#10003;</span>{t("amenities.cabinFacilities.description2")}</li>
                            </ul>
                        </div>
                        <div>
                            <h3>{t("amenities.bathroom.title")}</h3>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.bathroom.description1")}</li>
                                <li><span>&#10003;</span>{t("amenities.bathroom.description2")}</li>
                                <li><span>&#10003;</span>{t("amenities.bathroom.description3")}</li>
                                <li><span>&#10003;</span>{t("amenities.bathroom.description4")}</li>
                                <li><span>&#10003;</span>{t("amenities.bathroom.description5")}</li>
                                <li><span>&#10003;</span>{t("amenities.bathroom.description6")}</li>
                                <li><span>&#10003;</span>{t("amenities.bathroom.description7")}</li>
                            </ul>
                        </div>
                        <div>
                            <h3>{t("amenities.accessibility.title")}</h3>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.accessibility.description1")}</li>
                            </ul>
                        </div>
                        <div>
                            <h3>{t("amenities.exterior.title")}</h3>
                            <p>{t("amenities.exterior.subtitle")}</p>
                            <ul>
                                <li><span>&#10003;</span>{t("amenities.exterior.description1")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}