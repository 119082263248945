import React, { Fragment } from "react";

import Principal from "../principal/Principal.jsx";
import Pictures from "../pictures/Pictures.jsx";
import About from "../about/About.jsx";
import Amenites from "../amenites/Amenites.jsx";
import Reviews from "../reviews/Reviews.jsx";
import Contact from "../contact/Contact.jsx";


function Main() {
    return (
        <Fragment>
            <Principal/>
            <Pictures />
            <About />
            <Amenites />
            <Reviews />
            <Contact />
        </Fragment>
    )
}

export default Main