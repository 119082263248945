import React from "react";
import './principal.css'
import navbarBackground  from "../../static/navbarBackground.jpg"
import { useTranslation } from "react-i18next";

export default function Principal() {
    const [ t ] = useTranslation("global")

    return (
        <>
        <div className="principal-container">
            <div className="background-image">
                <img src={navbarBackground} alt=""/>
            </div>
            <div className="content-overlay">
                <h1 className="principal-h1">{t("principal.title")}</h1>
                <h4 className="principal-h4">{t("principal.subtitle")}</h4>
                <button className="principal-btn">
                        <a href="#contact">{t("principal.button")}</a>                        
                    </button>
            </div>
        </div>
        </>
    )
}