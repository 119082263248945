import React, { useState, useRef } from "react";
import './form.css'
import emailjs from '@emailjs/browser';
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
    const [t, i18n] = useTranslation("global")
    const [dataContact, setDataContact] = useState({
         name: {placeholder: t("form.name"), type: "text", value: '' },
         numPax:{ placeholder: t("form.pax"), type: "number", value: (1, 'pax') },
         phone:{ placeholder: t("form.phone"), type: "text", value: '' },
         email:{ placeholder: t("form.email"), type: "email", value: '' },
         message:{ placeholder: t("form.message"), type: "text", value: '' }
    })
    const [sendButtonContact, setSendButtonContact] = useState(t("form.button"))
    const [validCaptcha, setValidCaptcha] = useState(false)

    const formRef = useRef()
    const captcha = useRef(null)
    
    const handleChange = e => {
        const { name, value } = e.target
        setDataContact(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value
            }
        })
        )
    }


    const onChange = (value) => {
        setValidCaptcha(value !== null)
    }

    const resetCaptcha = () => {
        if (captcha.current) {
            captcha.current.reset();
        }
    };

    const sendDataContact = e => {
        e.preventDefault()
        if (validCaptcha) {
            setSendButtonContact('SENDING')
            emailjs.sendForm(
                'service_dym3vaq', 
                'template_el26f7d', 
                formRef.current, 
                'THGsEy0f2bF9DvlVu')
              .then((result) => {
                swal({
                    title: "El mail fue enviado correctamente",
                    text: "¡Muchas gracias por contactarnos! Nos contactaremos con Ud. a la brevedad",
                    icon: "success",
                    button: "accept",
                    timer: "2000"
                });
                setTimeout(() => {
                    setSendButtonContact('SEND')
                    resetCaptcha()
                }, 3000);
                setSendButtonContact('SENT')
                dataContact.name.value = ''
                dataContact.numPax.value = ''
                dataContact.phone.value = ''
                dataContact.email.value = ''
                dataContact.message.value = ''
              }, (error) => {
                swal({
                    title: "The email couldn't be sent",
                    icon: "error",
                    button: "accept",
                    timer: "2000"
                });
                  console.log(error.text);
              });
        } else {
            swal({
                title: "Confirme que no es un robot haciendo clic en el reCaptcha",
                icon: "error",
                button: "accept",
                timer: "2000"
            });
        }
        
    }

    return (
        <div className="div-form">
            <h3>{t("form.title")}</h3>
            <form ref={formRef} onSubmit={e => sendDataContact(e)} className="form">
                {Object.keys(dataContact).map(keyName => {
                    if (keyName !== 'message') return <input
                        key={keyName}
                        type={dataContact[keyName].type}
                        placeholder={dataContact[keyName].placeholder}
                        name={keyName}
                        value={dataContact[keyName].value}
                        onChange={e => handleChange(e)}
                        required
                    />
                    return <textarea
                        key={keyName}
                        type={dataContact[keyName].type}
                        placeholder={dataContact[keyName].placeholder}
                        name={keyName}
                        value={dataContact[keyName].value}
                        onChange={e => handleChange(e)}
                        required
                    />
                })}
                <div className="div-recaptcha">
                <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LdOWA0pAAAAABgskdwNK9UjMzXUlONb4ooow9lH"
                    onChange={onChange}
                />
                </div>
                <div className="button-form" >
                    <button type="submit">{sendButtonContact}</button>
                </div>
            </form>

        </div>
    )
}