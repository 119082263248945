import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Main from './components/main/Main';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Contact from './components/contact/Contact';
import Pictures from './components/pictures/Pictures';
import About from './components/about/About'
import Amenites from './components/amenites/Amenites';
import Reviews from './components/reviews/Reviews';
import WebpageProvider from './context/WebpageProvider';


function App() {
  const [userBookingValue, setUserBookingValue] = useState({
    name: {placeholder: "Name & Surname", type: "text", value: '' },
    numPax:{ placeholder: "Pax", type: "number", value: 1 },
    checkIn:{ placeholder: "Check In", type: "date", value: new Date() },
    checkOut:{ placeholder: "Check Out", type: "date", value: new Date() },
    phone:{ placeholder: "Phone", type: "text", value: '' },
    email:{ placeholder: "Email", type: "email", value: '' },
    message:{ placeholder: "Message", type: "text", value: '' }
  })

  const handleChangeBookingValues = (value, name) => {
    setUserBookingValue((prevState) => {
      return {...prevState, [name]:{...prevState[name], value}}
      
    })
  }

  return (
    <WebpageProvider 
      userBookingValue = {userBookingValue}
      handleChangeBookingValues = {handleChangeBookingValues}
    >

      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pictures" element={<Pictures />} />
          <Route path="/about" element={<About />} />
          <Route path="/amenites" element={<Amenites />} />
          <Route path="/reviews" element={<Reviews />} />
        </Routes>
      </Router>
    </WebpageProvider>
  );
}

export default App;
