import React, { Fragment, useState } from "react";
import './pictures.css'
import picturesData from '../../mockupData/picturesData.json'
import Modal from "./modalImg/ModalImg";
import { useTranslation } from "react-i18next";


export default function Pictures() {
    const [t] = useTranslation("global")
    const [imagePerRow, setImagePerRow] = useState(6)
    const [clickedImg, setClickedImg] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(null)
    
    const [next, setNext] = useState(imagePerRow);
    const handleMoreIcons = () => {
        setNext(next + imagePerRow);
    };

    const handleClick = (item, index) => {
        setCurrentIndex(index)
        setClickedImg(item.img)
    }
    const handleRotationRight = () => {
        const totalLength = picturesData.pictures.length
        if(currentIndex +1 >= totalLength) {
            setCurrentIndex(0)
            const newUrl = picturesData.pictures[0].img
            setClickedImg(newUrl)
            return
        }
        const newIndex = currentIndex + 1
        const newUrl = picturesData.pictures.filter(item => {
            return picturesData.pictures.indexOf(item) === newIndex
        })
        const newItem = newUrl[0].img
        setClickedImg(newItem)
        setCurrentIndex(newIndex)
    }

    const handleRotationLeft = () => {
        const totalLength = picturesData.pictures.length
        if(currentIndex +1 >= totalLength) {
            setCurrentIndex(0)
            const newUrl = picturesData.pictures[0].img
            setClickedImg(newUrl)
            return
        }
        const newIndex = currentIndex - 1
        const newUrl = picturesData.pictures.filter(item => {
            return picturesData.pictures.indexOf(item) === newIndex
        })
        const newItem = newUrl[0].img
        setClickedImg(newItem)
        setCurrentIndex(newIndex)
      };

    return (
        <Fragment>
            <div id="gallery" className="row pictures-div">
                <div className="div-text-pictures">
                    <h3 className="title-pictures">{t("pictures.title")}</h3>
                </div>
                    <div className="wrapper" >
                        {picturesData.pictures.slice(0, next).map((item, index) => (
                            <div key={index} className="wrapper-images">
                                <img
                                    className="img"
                                    src={item.img}
                                    alt='/'
                                    onClick={() => handleClick(item, index)}
                                />
                            </div>
                        ))}
                    </div>
                
                {next < picturesData.pictures.length && (
                <div className="div-pictures-button">
                    <button className="principal-btn" onClick={handleMoreIcons}>{t("pictures.button")}</button>
                </div>
                )}
                {clickedImg && (
                    <Modal 
                        clickedImg={clickedImg} 
                        handleRotationRight={handleRotationRight}
                        setClickedImg={setClickedImg}
                        handleRotationLeft={handleRotationLeft}
                        />
                    )}
            </div>
        </Fragment>
    )
}