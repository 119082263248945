import React, { useState } from "react";
import './navbar.css'
import Logo from "../logo/Logo";
// import AnchorLink from "react-anchor-link-smooth-scroll"
import BurgerIcon from "./burgerIcon/BurgerIcon";
import { useTranslation } from "react-i18next";
import españa from "../../img/españa.png"
import inglaterra from "../../img/inglaterra.png"

const translations = {
    gallery: "navbar.gallery",
    about: "navbar.about",
    services: "navbar.services",
    reviews: "navbar.reviews",
    contact: "navbar.contact",
  };

export default function Navbar() {
    const [t, i18n] = useTranslation("global")
    const [burgerIconClicked, setBurgerIconClicked] = useState(false)

    const handleButtonClick = () => {
        setBurgerIconClicked(!burgerIconClicked)
    }

    const renderLanguageButtons = () => {
    return (
      <div>
        <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => i18n.changeLanguage("es")}>
          <img style={{ width: "30px", height: "30px" }} src={españa} alt="ES" />
        </button>
        <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => i18n.changeLanguage("en")}>
          <img style={{ width: "30px", height: "30px" }} src={inglaterra} alt="EN" />
        </button>
      </div>
    );
  };

    const renderMenuItems = () => {
        return (
          <div className={`menu-items ${burgerIconClicked ? 'active' : ''}`}>
            {Object.keys(translations).map((key) => (
              <a
                key={key}
                onClick={burgerIconClicked ? handleButtonClick : null}
                className="a-contact-navbar"
                href={`#${key}`}
              >
                {t(translations[key])}
              </a>
            ))}
            {renderLanguageButtons()}
          </div>
        );
      };
    return(
        <div className="div-navbar">
        <Logo />
        <div className="navbar-items">
          {renderMenuItems()}
          <div className="burgerIcon">
            <BurgerIcon burgerIconClicked={burgerIconClicked} handleClick={handleButtonClick} />
          </div>
          <div className={`initial ${burgerIconClicked ? 'active' : ''}`}></div>
        </div>
      </div>
    )
}